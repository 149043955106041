function formatNumber(num) {
    if (num >= 1000000) {
        return (num / 1000000).toFixed(1) + 'M';
    } else if (num >= 1000) {
        return (num / 1000).toFixed(1) + 'K';
    }
    return num;
}

async function fetchYouTubeData() {
    try {
        const response = await fetch('https://api.jed.world/api/public/getYouTubeData');
        const data = await response.json();
        
        document.getElementById('viewCount').textContent = formatNumber(data.viewCount);
        document.getElementById('subscriberCount').textContent = formatNumber(data.subscriberCount);
        document.getElementById('videoCount').textContent = data.videoCount;
        document.getElementById('loading').style.display = 'none';
    } catch (error) {
        console.error('Error fetching YouTube data:', error);
        document.getElementById('loading').textContent = 'Error loading statistics. Please try again later.';
    }
}

// Fetch data when page loads
fetchYouTubeData();